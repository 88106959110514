import React from 'react';
import { useParams } from 'react-router';
import { Card, CardBody } from 'reactstrap';

import ContentWithAsideLayout from 'layouts/ContentWithAsideLayout';
import { getCalendarCalendarItems } from 'api/calendar-items';
import { useCalendar } from 'context/calendars';
import { moveEvent, addEventFromBacklog } from 'actions/calendars';
import { useCustomTitle, useToastError } from 'utils';
import Loader from 'falcon/components/Loader';
import FalconCardHeader from 'falcon/components/FalconCardHeader';
import ButtonIcon from 'falcon/components/ButtonIcon';

import { CalendarEventTooltip, EditCalendarItemModal, useEditCalendarItemModal } from 'atoms/calendars';

import Calendar from './Calendar';
import CreateCalendarItemModal from './CreateCalendarItemModal';
import Header from './Header';
import CalendarEventBox from './CalendarEventBox';
import { views, resourceFormatters } from 'atoms/calendars/views';
import { useHistory } from 'react-router-dom';

export default function ViewCalendar() {
  const { id } = useParams();
  const [calendarApi, setCalendarApi] = React.useState();
  const { calendarData, selectCalendarViewDropdownProps, calendarProps, formatters } = useCalendar(
    id,
    calendarApi,
    views,
    resourceFormatters
  );

  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/admin/data-sync');
  };

  // Sets the title to the calendar name while this component is mounted.
  useCustomTitle(`PlanBoard - ${calendarData?.name}`);

  const [calendarItems, setCalendarItems] = React.useState([]);
  const setError = useToastError();

  React.useEffect(() => {
    async function loadCalendarItems() {
      await getCalendarCalendarItems(id, setCalendarItems, setError);
    }
    loadCalendarItems();
  }, [id, setError]);

  const [createCalendarItemModalState, setCreateCalendarItemModalState] = React.useState({
    open: false,
    initialStartDate: null,
    initialEndDate: null
  });

  const toggleCreateCalendarItemModal = () => {
    setCreateCalendarItemModalState(cur => ({
      ...cur,
      open: !cur.open
    }));
  };

  const handleCalendarDateClick = event => {
    setCreateCalendarItemModalState(cur => ({
      ...cur,
      open: true,
      initialStartDate: event.start,
      initialEndDate: event.end
    }));
  };

  const { editCalendarModalProps, handleCalendarEventClick } = useEditCalendarItemModal(() => calendarData);

  const [currentViewTotalValue, setCurrentViewTotalValue] = React.useState(0);
  const [title, setTitle] = React.useState('');
  const [backlogProjects, setBacklogProjects] = React.useState([]);

  return (
    <>
      <ContentWithAsideLayout
        fullHeight
        ratio={[5, 1]}
        aside={
          <Card className="mt-3">
            <FalconCardHeader title="Intelligent Copilot" />
            <CardBody style={{ height: '600px', overflowY: 'scroll' }}>
              <div className="">
                <p>Take advantage of our automated, intelligent planning features.</p>
                <h4>Create Content Plan</h4>
                <p>Base on your needs, our AI will create a new content plan for you.</p>
                <ButtonIcon
                  icon="sync-alt"
                  transform="shrink-3 down-2"
                  color="falcon-default"
                  size="sm"
                  className="m-2"
                  onClick={handleButtonClick}
                >
                  Create Content Plan
                </ButtonIcon>
              </div>

              {/* <Backlog
                toggleRefresh={toggleBacklogRefresh}
                calendarData={calendarData}
                backlogProjects={backlogProjects}
                setBacklogProjects={setBacklogProjects}
              /> */}
            </CardBody>
          </Card>
        }
        banner={
          <Header
            title={title}
            calendarData={calendarData}
            currentViewTotalValue={currentViewTotalValue}
            calendarApi={calendarApi}
            toggleCreateCalendarItemModal={toggleCreateCalendarItemModal}
            selectCalendarViewDropdownProps={selectCalendarViewDropdownProps}
          />
        }
      >
        <div className="pt-3 h-100">
          <Card style={{ height: '98%' }}>
            {calendarData ? (
              <Calendar
                resources={formatters.resourceFormatter(calendarData.tag_set)}
                events={calendarItems.map(formatters.eventFormatter)}
                initialView={calendarData.default_initial_view}
                eventDidMount={({ event }) => {
                  // Add new events' values to the total.
                  setCurrentViewTotalValue(cur => cur + Number(event.extendedProps.calendar_metric_value));
                }}
                eventWillUnmount={({ event }) => {
                  // Remove removed events' values from the total.
                  setCurrentViewTotalValue(cur => cur - Number(event.extendedProps.calendar_metric_value));
                }}
                datesSet={({ view }) => {
                  // This runs whenever the calendar changes dates.
                  setTitle(view.title);
                }}
                setCalendarApi={setCalendarApi}
                select={handleCalendarDateClick}
                eventChange={e => {
                  moveEvent(e, setCalendarItems, setError, val => val);
                }}
                eventReceive={e => {
                  addEventFromBacklog(e, setCalendarItems, setError, setBacklogProjects);
                }}
                eventClick={handleCalendarEventClick}
                eventContent={({ event }) => (
                  <>
                    <CalendarEventBox
                      project={event.extendedProps.project}
                      calendarData={calendarData}
                      calendarItem={event.extendedProps}
                      id={`event_block_${event.id}`}
                    />
                    <CalendarEventTooltip
                      event={event}
                      calendarData={calendarData}
                      project={event.extendedProps.project}
                      target={`event_block_${event.id}`}
                      placement="right"
                      autohide={false}
                    />
                  </>
                )}
                resourceLabelDidMount={function(info) {
                  info.el.style.backgroundColor = info.resource.extendedProps.color;
                  info.el.style.color = 'white';
                }}
                {...calendarProps}
              />
            ) : (
              <Loader />
            )}
          </Card>
        </div>
        <CreateCalendarItemModal
          backlogProjects={backlogProjects}
          open={createCalendarItemModalState.open}
          toggle={toggleCreateCalendarItemModal}
          initialStartDate={createCalendarItemModalState.initialStartDate}
          initialEndDate={createCalendarItemModalState.initialEndDate}
          setCalendarItems={setCalendarItems}
          calendarData={calendarData}
        />
        <EditCalendarItemModal {...editCalendarModalProps} setCalendarItems={setCalendarItems} />
      </ContentWithAsideLayout>
    </>
  );
}
