import React from 'react';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { toast } from 'react-toastify';

import { useToastError } from 'utils';
import { generateImage } from 'api/projects/createProject';
import { useAuthContext } from 'context/auth';

export default function GenerateForProjectButton({ id, onSuccess = () => {}, ...buttonIconProps }) {
  const setError = useToastError();
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();

  async function handleGenerate() {
    const [, responseError] = await generateImage(id);
    if (responseError) {
      setError(`An error occurred and generating the ${singularTitle} image failed.`);
      return;
    }
    toast.success(`${singularTitle} image generated.`);
    onSuccess(id);
  }

  return (
    <ButtonIcon onClick={handleGenerate} {...buttonIconProps}>
      Generate
    </ButtonIcon>
  );
}
