import React from 'react';
import { FormGroup, Label, Input, Button, Row, Col } from 'reactstrap';
import { getConsumerPersonaMiniHelper, getGeographyMiniHelper } from 'api/gpt';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { StyledTooltip } from 'atoms/misc';

export default function StepConsumer({ wizardData, handleChanged }) {
  const [loading, setLoading] = useState(false);

  const handleConsumerPersonaMiniHelperClick = async () => {
    setLoading(true);
    const input_text = wizardData['ideal_customers'];
    const [result, error] = await getConsumerPersonaMiniHelper(input_text);

    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    const gptResult = {
      target: {
        id: 'ideal_customers',
        value: input_text + '\nBurgundy: ' + result.data
      }
    };
    handleChanged(gptResult);
    setLoading(false);
  };

  const handleGeographyMiniHelperClick = async () => {
    setLoading(true);
    const input_text = wizardData['consumer_geography'];
    const [result, error] = await getGeographyMiniHelper(input_text);

    if (error) {
      toast.error(error);
      setLoading(false);
      return;
    }

    const gptResult = {
      target: {
        id: 'consumer_geography',
        value: input_text + '\nBurgundy: ' + result.data
      }
    };
    handleChanged(gptResult);
    setLoading(false);
  };

  return (
    <FormGroup>
      <Label for="ideal_customers">Who are your ideal customers? Your target Audience? (Required)</Label>

      <Row>
        <Col md={9}>
          <Input
            id="ideal_customers"
            type="textarea"
            rows={3}
            value={wizardData['ideal_customers'] || ''}
            onChange={handleChanged}
            placeholder="e.g. Parents, Grandparents, ..."
            invalid={!wizardData['ideal_customers']}
          />
        </Col>
        <Col md={3}>
          <Button outline onClick={handleConsumerPersonaMiniHelperClick}>
            <div id="minihelper-consumer-persona">{loading ? '💡 Loading...' : '💡'}</div>
            <StyledTooltip show target="minihelper-consumer-persona">
              Click the lightbulb to activate Burgundy Mini to help you ideate and elaborate on the Ideal Customer
              persona for your content plan.
            </StyledTooltip>
          </Button>
        </Col>
      </Row>

      <Label for="consumer_geography">
        In what geographical areas do you offer your product or service? (Optional)
      </Label>
      <Row>
        <Col md={9}>
          <Input
            id="consumer_geography"
            type="textarea"
            rows={2}
            value={wizardData['consumer_geography'] || ''}
            onChange={handleChanged}
            placeholder="e.g. North America, Canada, Tri-state region, ..."
          />
        </Col>
        <Col md={3}>
          <Button outline onClick={handleGeographyMiniHelperClick}>
            <div id="minihelper-geography">{loading ? '💡 Loading...' : '💡'}</div>
            <StyledTooltip show target="minihelper-geography">
              Click the lightbulb to activate Burgundy Mini to help you ideate and elaborate on Geography for the
              purposes of your content plan.
            </StyledTooltip>
          </Button>
        </Col>
      </Row>

      <legend>Tell us about your product or service</legend>
      <Label for="consumer_solution">In summary, what does your solution do? (Required, 400 characters max)</Label>
      <Input
        id="consumer_solution"
        type="textarea"
        rows={2}
        value={wizardData['consumer_solution'] || ''}
        onChange={handleChanged}
        placeholder="e.g. UberGarment: Cozy, high-quality kidswear for chilly adventures and everyday warmth"
        maxLength={400}
      />

      <Label for="consumer_solution_details">Describe in details, what your solution does?</Label>
      <Input
        id="consumer_solution_details"
        value={wizardData['consumer_solution_details'] || ''}
        type="textarea"
        rows={5}
        placeholder="e.g. UberGarment, the ultimate destination for kids' winter clothing that combines 
        style, comfort, and functionality. Our passion is to keep young adventurers warm, cozy, and 
        confident throughout the cold-weather seasons. With a focus on high-quality materials, thoughtful 
        design, and attention to detail, we create garments that not only look great but also perform 
        exceptionally well."
        onChange={handleChanged}
      />

      <Label for="consumer_competitors">Who are your direct competitors? (Optional)</Label>
      <Input
        id="consumer_competitors"
        value={wizardData['consumer_competitors'] || ''}
        onChange={handleChanged}
        placeholder="e.g. Helly Hansen, Columbia, ..."
      />
    </FormGroup>
  );
}
