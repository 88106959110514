import React from 'react';

import { EditableFieldWithPatchRequest, DateInput } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';

export default function CalendarItemDateFields({ onUpdateSuccess, updateCalendarItem, initialState }) {
  return (
    <>
      <MediaWithIconAndHeading
        icon="calendar-check"
        heading="Date"
        content={
          <EditableFieldWithPatchRequest
            label="Date"
            initialValue={initialState.start}
            patchRequest={updateCalendarItem}
            fieldName="start"
            onSuccess={onUpdateSuccess}
            onChange={(userInputDate, setNewValue) => {
              setNewValue(userInputDate);
            }}
            groupedInputProps={{
              showLabelText: false,
              InputComponent: DateInput,
              inputRefPropName: 'ref',
              hasAddon: true
            }}
          />
        }
      />
    </>
  );
}
