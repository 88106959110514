import React from 'react';
import { Modal, ModalHeader, ModalBody, NavLink, ModalFooter, Badge } from 'reactstrap';
import { toast } from 'react-toastify';

import { patchCalendarItem } from 'api/calendar-items';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { useAuthContext } from 'context/auth';
import { DatedNoteTextArea } from 'atoms/form';
import { MediaWithIconAndHeading } from 'atoms/misc';
import { useProjectFields } from 'atoms/projects';
import { updateCalendarItems } from 'actions/calendars';

import CalendarItemDateFields from './CalendarItemDateFields';
import DeleteCalendarItemButton from './DeleteCalendarItemButton';
import CalendarItemTagsModal from './CalendarItemTagsModal';

export default function EditCalendarItemModal({
  calendarData,
  open,
  toggle,
  setCalendarItems,
  initialState,
  id,
  transformCalendarItem = item => item,
  refreshBacklog = () => {}
}) {
  const [hasOpenTagsModal, setHasOpenTagsModal] = React.useState(false);
  const [tags, setTags] = React.useState([]);

  React.useEffect(() => {
    if (initialState) {
      setTags(initialState.tags);
    }
  }, [initialState]);

  const updateCalendarItem = async body => await patchCalendarItem(id, body);

  function deleteSuccessCallback() {
    updateCalendarItems(transformCalendarItem, setCalendarItems, refreshBacklog, {
      removedItemId: id
    });
    toggle();
  }

  function onUpdateSuccess(updatedCalendarItem) {
    toast.success('Calendar Item updated.');
    updateCalendarItems(transformCalendarItem, setCalendarItems, refreshBacklog, {
      removedItemId: id,
      addedItem: updatedCalendarItem
    });
  }

  const project = initialState?.project;
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();
  const projectFields = useProjectFields();
  return (
    <>
      <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          <span className="mr-3">"{project?.name}"</span>
          {tags.map(tag => (
            <Badge key={tag.id} style={{ backgroundColor: tag.color }} className="mx-1" pill>
              {tag.label}
            </Badge>
          ))}
          <Badge
            aria-label="Edit tags"
            tag="button"
            pill
            className="mx-1 btn"
            onClick={() => setHasOpenTagsModal(true)}
          >
            +
          </Badge>
        </ModalHeader>
        <ModalBody>
          {projectFields.map(({ key, label }) => (
            <p className="mb-1" key={`project-${key}`}>
              <strong>{label}: </strong>
              {project && project[key]}
            </p>
          ))}
          {project?.image_url && <img src={project.image_url} alt={project.name} className="w-50 mb-3" />}
          <CalendarItemDateFields
            onUpdateSuccess={onUpdateSuccess}
            updateCalendarItem={updateCalendarItem}
            initialState={initialState}
          />
          <MediaWithIconAndHeading icon="pencil-alt" heading="Note">
            <DatedNoteTextArea
              label="Note"
              initialValue={initialState.note}
              patchRequest={updateCalendarItem}
              fieldName="note"
              onSuccess={onUpdateSuccess}
            />
          </MediaWithIconAndHeading>
        </ModalBody>
        <ModalFooter className="bg-light px-card border-top-0">
          <ButtonIcon
            icon="link"
            Component={NavLink}
            className="py-1 px-2 m-2 btn btn-falcon-default btn-sm"
            href={`/projects/${project?.id}`}
            target="_blank"
          >
            {singularTitle} Details
          </ButtonIcon>
          <DeleteCalendarItemButton
            icon="trash-alt"
            size="sm"
            className="m-2 btn-falcon-danger"
            id={id}
            onSuccess={deleteSuccessCallback}
          />
        </ModalFooter>
      </Modal>
      <CalendarItemTagsModal
        calendarData={calendarData}
        open={hasOpenTagsModal}
        toggle={() => {
          setHasOpenTagsModal(cur => !cur);
        }}
        setCalendarItems={setCalendarItems}
        initialState={initialState}
        id={id}
        transformCalendarItem={transformCalendarItem}
        refreshBacklog={refreshBacklog}
        onSuccess={calendarItem => {
          setTags(calendarItem.tags);
        }}
      />
    </>
  );
}
