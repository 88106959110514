import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import FalconCardHeader from 'falcon/components/FalconCardHeader';

import { useAuthContext } from 'context/auth';

import DataSyncSection from './DataSyncSection';
import UserManagementSection from './UserManagementSection';
import CompanySettingsSection from './CompanySettingsSection';
import CalendarManagementSection from './CalendarManagementSection';

import { projectName } from 'config';

export default function AdminPage() {
  const { authState } = useAuthContext();
  const { path } = useRouteMatch();
  return authState.user?.isAdmin ? (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Settings" light={false} />
        <CardBody>
          Welcome to the {authState.user.userdetails.company.name} administration area. Here you will find settings and
          tools to customize and extend <b>{projectName}</b> to best fit your organization's needs.
          <hr />
        </CardBody>
      </Card>
      <Switch>
        <Route path={`${path}/data-sync`}>
          <DataSyncSection />
        </Route>
        <Route path={`${path}/users`}>
          <UserManagementSection />
        </Route>
        <Route path={`${path}/company`}>
          <CompanySettingsSection />
        </Route>
        <Route path={`${path}/calendars`}>
          <CalendarManagementSection />
        </Route>
      </Switch>
    </>
  ) : (
    'Unauthorized'
  );
}
