import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

export default function StepFinal({ planOptionValue, planOptions, planHandlChanded }) {
  return (
    <FormGroup>
      <legend>The content plan</legend>
      <Label for="plans_options">What format of content would you like us to generate?</Label>
      <Select
        id="plan_options"
        defaultValue={planOptionValue}
        value={planOptionValue}
        options={planOptions}
        onChange={planHandlChanded}
        isSearchable={false}
        isClearable={false}
      />
    </FormGroup>
  );
}
