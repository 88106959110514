import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import editing from 'assets/img/dashboard.png';
import { projectName } from 'config';

const Starter = () => (
  <Card>
    <CardBody className="overflow-hidden p-lg-6">
      <Row className="align-items-center justify-content-center">
        <Col lg={8}>
          <img src={editing} className="img-fluid" alt="" />
        </Col>
        <Col lg={3} className="pl-lg-4 my-0 text-center text-lg-left">
          <h3>{projectName}</h3>
          <p className="">
            A shared view of content calendars and plans within your organization. Members of your team can see and
            manage each calendar.
          </p>
          <Link className="btn btn-falcon-primary mb-3" to="/calendars">
            Calendars
          </Link>
          <p className="">
            You can also ask <b>Burgundy</b>
            <span role="img" aria-label="idea lightbulb">
              💡
            </span>{' '}
            your sophesticated, yet approachable content idea generation assistant.
          </p>
          <Link className="btn btn-falcon-primary" to="/admin/data-sync">
            Ask Burgundy
          </Link>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default Starter;
