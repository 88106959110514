import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';

export default function StepBegin({ customerTypeValue, customerTypOptions, customerTypesChanged }) {
  return (
    <FormGroup>
      <Label for="functions">Who is the target audience for your product or service?</Label>
      <Select
        id="target_customers"
        defaultValue={customerTypeValue}
        value={customerTypeValue}
        options={customerTypOptions}
        onChange={customerTypesChanged}
        isSearchable={false}
        isClearable={false}
      />
    </FormGroup>
  );
}
