import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';

import { FalconContext } from 'falcon';
import useRoutes from 'useRoutes';

import NavbarVerticalMenuItem from './NavbarVerticalMenuItem';
import NavbarVerticalDropdown from './NavbarVerticalDropdown';

const NavbarVerticalMenu = () => {
  const { setShowBurgerMenu } = useContext(FalconContext);
  const routes = useRoutes();

  return (
    <nav>
      {routes.map(route => {
        if (!route.children) {
          return (
            <NavItem key={route.id}>
              <NavLink exact={route.exact} className="nav-link" to={route.to} onClick={() => setShowBurgerMenu(false)}>
                <NavbarVerticalMenuItem icon={route.icon} name={route.name} />
              </NavLink>
            </NavItem>
          );
        }
        return (
          <NavbarVerticalDropdown
            key={route.id}
            initialIsOpen={route.initialIsOpen}
            icon={route.icon}
            name={route.name}
          >
            {route.children.map(childRoute => {
              return (
                <NavLink
                  key={childRoute.id}
                  className="nav-link ml-3"
                  to={childRoute.to}
                  onClick={() => setShowBurgerMenu(false)}
                >
                  <NavbarVerticalMenuItem
                    iconSpanClassName="nav-link-icon nav-link-dropdown-item"
                    stackedIcon={childRoute.stackedIcon}
                    icon={childRoute.icon}
                    name={childRoute.name}
                    iconColor={childRoute.iconColor}
                  />
                </NavLink>
              );
            })}
          </NavbarVerticalDropdown>
        );
      })}
    </nav>
  );
};

export default NavbarVerticalMenu;
