import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';

import FalconCardHeader from 'falcon/components/FalconCardHeader';
import { useAuthContext } from 'context/auth';
import { getCompanyDetails, patchCompany } from 'api/companies';
import { EditableFieldWithPatchRequest } from 'atoms/form';

const FAILED_UPDATE_ERROR_MESSAGE = 'Failed to update company.';

export default function CompanySettingsSection() {
  const [company, setCompany] = React.useState({});
  const {
    authState: { user },
    authDispatch
  } = useAuthContext();

  React.useEffect(() => {
    if (!user) return;
    async function request() {
      const [res, err] = await getCompanyDetails(user.userdetails.company.id);
      if (err) {
        toast.error(err);
        return;
      }
      setCompany(res.data);
    }
    request();
  }, [user]);

  const patchCompanyRequest = async body => await patchCompany(company.id, body);

  function onSuccess(updatedCompany) {
    setCompany(updatedCompany);
    authDispatch({
      type: 'UPDATE_COMPANY_DETAILS',
      payload: updatedCompany
    });
    toast.success('Updated successfully');
  }

  return (
    <Card className="mb-3">
      <FalconCardHeader title="Team Settings" light={false} />
      <CardBody className="p-3">
        <EditableFieldWithPatchRequest
          initialValue={company.name || ''}
          label="Name"
          patchRequest={patchCompanyRequest}
          fieldName="name"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={company.logo_url || ''}
          label="Link to Logo"
          patchRequest={patchCompanyRequest}
          fieldName="logo_url"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={company.project_label_singular || ''}
          label="How do you refer to an item of work (e.g. post, blog post, tweet, ...)"
          patchRequest={patchCompanyRequest}
          fieldName="project_label_singular"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
        <EditableFieldWithPatchRequest
          initialValue={company.project_label_plural || ''}
          label="How do you refer to multiple items of work (e.g. posts, blog posts, tweets, ...)"
          patchRequest={patchCompanyRequest}
          fieldName="project_label_plural"
          errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
          onSuccess={onSuccess}
        />
      </CardBody>
    </Card>
  );
}
