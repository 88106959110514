import 'react-toastify/dist/ReactToastify.min.css';
import 'react-image-lightbox/style.css';
import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import { FalconProvider } from 'falcon';
import { DashboardLayout, AuthBasicLayout } from 'layouts';
import { CloseButton, Fade } from 'falcon/components/Toast';
import { Error404 } from 'views/errors';
import { LoginForm, LogoutConfirmationPage, AccountVerificationLandingPage } from 'views/auth';
import { ViewCalendar, ProjectTimelineCalendar, RedirectToFirstCalendar } from 'views/calendars';
import { CalendarsListProvider } from 'context/calendars';
import { ProjectDetailsPage, ProjectsList } from 'views/projects';
import HomePage from 'views/HomePage';
import AdminPage from 'views/AdminPage';
import { AuthRequired } from 'atoms/users';

const App = () => {
  return (
    <FalconProvider>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route path="/auth">
            <AuthBasicLayout>
              <Switch>
                <Route path="/auth/login" exact component={LoginForm} />
                <Route path="/auth/logout" exact component={LogoutConfirmationPage} />
                <Route path="/auth/verify" component={AccountVerificationLandingPage} />
                <Error404 homePath="/auth/login" />
              </Switch>
            </AuthBasicLayout>
          </Route>
          <Route>
            <AuthRequired>
              <CalendarsListProvider>
                <DashboardLayout>
                  <Switch>
                    <Route path="/calendars" exact component={RedirectToFirstCalendar} />
                    <Route path="/calendars/:id" exact component={ViewCalendar} />
                    <Route path="/projects" exact component={ProjectsList} />
                    <Route path="/projects/:id" exact component={ProjectDetailsPage} />
                    <Route path="/project-timeline" component={ProjectTimelineCalendar} />
                    <Route path="/admin">
                      <AdminPage />
                    </Route>
                    <Route path="/" exact>
                      <HomePage />
                    </Route>
                    <Error404 homePath="/" />
                  </Switch>
                </DashboardLayout>
              </CalendarsListProvider>
            </AuthRequired>
          </Route>
        </Switch>
      </Router>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </FalconProvider>
  );
};

export default App;
