import React from 'react';

import ButtonIcon from 'falcon/components/ButtonIcon';

export default function NavButton({ name, type, icon, onClickHandle }) {
  return (
    <ButtonIcon color="falcon-primary" type={type} icon={icon} size="sm" className="m-2" onClick={onClickHandle}>
      {name}
    </ButtonIcon>
  );
}
