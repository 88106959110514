import { useAuthContext } from 'context/auth';

export default function useProjectFields() {
  const { authState } = useAuthContext();

  return [
    {
      key: 'name',
      label: `${authState.projectLabels.singularTitle} Name`,
      required: true
    },
    {
      key: 'external_id',
      label: 'External Id'
    },
    {
      key: 'address',
      label: `${authState.projectLabels.singularTitle} Address`
    },
    {
      key: 'author',
      label: 'Author'
    },
    {
      key: 'content',
      label: 'Content'
    }
  ];
}
