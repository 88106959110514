import React from 'react';
import Select from 'react-select';
import { FormGroup, Label } from 'reactstrap';

import { getProjects as apiCall } from 'api/projects';

export default function SelectProjectDropdown({ options = null, state, setState, label, ...reactSelectProps }) {
  const [projects, setProjects] = React.useState();

  React.useEffect(() => {
    if (options !== null) return;
    async function getProjects() {
      const [res, err] = await apiCall();
      // TODO: Add an error for user if fails.
      if (!err) {
        setProjects(res.data);
      }
    }
    getProjects();
  }, [options]);

  return (
    <FormGroup>
      <Label for="existing-project">{label}</Label>
      <Select
        id="existing-project"
        onChange={project => {
          setState(project);
        }}
        value={state}
        options={options || projects}
        isLoading={!options && !projects}
        formatOptionLabel={({ id, name, author }) => {
          let result = `${id}: ${name}`;
          if (author) {
            result += ` - ${author}`;
          }
          return result;
        }}
        {...reactSelectProps}
      />
    </FormGroup>
  );
}
