import React from 'react';
import Select from 'react-select';
import { Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ButtonIcon from 'falcon/components/ButtonIcon';
import { postImageCalendarImport } from 'api/importers';
import { useCalendarsListContext } from 'context/calendars';
import { useAuthContext } from 'context/auth';

function CalendarSelect({ options, onChange }) {
  return <Select defaultValue={options[0]} options={options} onChange={onChange} />;
}

export default function BulkUploadCalendarDocModal({ open, toggle, setResults, setLoading }) {
  const [image, setImage] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [calendarOptions, setCalendarOptions] = React.useState();
  const [calendarId, setCalendarId] = React.useState();

  const { calendars } = useCalendarsListContext();

  const {
    authState: { projectLabels }
  } = useAuthContext();

  React.useEffect(() => {
    if (calendars.length > 0) {
      const options = calendars.map(({ id, name }) => ({
        value: id,
        label: name
      }));

      setCalendarOptions(options);
      setCalendarId(options[0].value);
    }
  }, [calendars]);

  const handleCapture = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = event => {
        setImage(event.target.result);
        setError(null);
      };
      reader.readAsDataURL(file);
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (!image) {
      setError('Please take a photo before submitting.');
      setLoading(false);
      return;
    }
    const [response, error] = await postImageCalendarImport(image, calendarId);
    setResults({ response, error });
    setLoading(false);
    toggle();
    setImage(null);
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          Bulk Upload {projectLabels.pluralTitle} from Photo
        </ModalHeader>
        <ModalBody>
          Select The Calendar to Import into:
          <CalendarSelect options={calendarOptions} onChange={e => setCalendarId(e.value)} />
          <br />
          <input
            type="file"
            accept="image/*"
            capture="environment"
            onChange={handleCapture}
            style={{ display: 'none' }}
            id="cameraInput"
          />
          <ButtonIcon
            onClick={() => document.getElementById('cameraInput').click()}
            color="falcon-default"
            icon="camera"
            size="sm"
            className="mr-3 mt-3"
          >
            Take Photo
          </ButtonIcon>
          {image && (
            <div className="mt-3">
              <img src={image} alt="Captured" style={{ maxWidth: '100%', maxHeight: '200px' }} />
            </div>
          )}
          {error && <div className="text-danger mt-2">{error}</div>}
        </ModalBody>
        <ModalFooter>
          <ButtonIcon color="falcon-primary" type="submit" icon="check" size="sm" className="m-2">
            Upload
          </ButtonIcon>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
