import React from 'react';
import { Table, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';

import { toast } from 'react-toastify';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { useCalendarsListContext } from 'context/calendars';
import { postChatGPTCalendarItemsImport } from 'api/importers';
import DatePicker from 'react-datepicker';

export default function IdeasTable({ ideas, plans, setInProgress }) {
  const days = plans.value === 'longForm' ? 7 : 2;
  const { calendars } = useCalendarsListContext();
  const calendarOptions = calendars.map(({ id, name }) => ({
    value: id,
    label: name
  }));

  const [data, setData] = React.useState(
    ideas.map((idea, index) => ({
      id: index,
      calendar: calendarOptions[0].value,
      scheduleDate: addDays(new Date(), days * index),
      ...idea
    }))
  );

  const handleAdd = async (e, id) => {
    setInProgress(true);
    const record = data.filter(idea => idea.id === id);

    record[0].scheduleDate = record[0]['scheduleDate'].toISOString().split('T')[0];
    const [, err] = await postChatGPTCalendarItemsImport(record);
    if (err) {
      toast.error(err['errors']);
      return;
    }

    // remove item from our data
    setData(data.filter(idea => idea.id !== id));
    setInProgress(false);
  };

  const calendarChanged = (value, id) => {
    data.find(idea => idea.id === id).calendar = value;
    setData(data);
  };

  const schaduleDateChanged = (date, id) => {
    data.find(idea => idea.id === id).scheduleDate = date;
    setData(data);
  };

  const rows = data.map(idea => {
    return (
      <IdeaRow
        row={idea}
        key={idea.id}
        currentDate={idea.scheduleDate}
        calendarOptions={calendarOptions}
        onApproved={handleAdd}
        setCalendar={calendarChanged}
        setSchaduleDate={schaduleDateChanged}
      />
    );
  });

  return (
    <Table class="table-container" width="100%" role="table">
      <thead>
        <tr class="flex-table header" role="rowgroup">
          <th class="flex-row first" role="columnheader">Title</th>
          <th class="flex-row" role="columnheader">Schedule Date</th>
          {calendarOptions && calendarOptions.length > 1 && <th class="flex-row" role="columnheader">Calendar</th>}          
        </tr>
      </thead>  
      <tbody class="flex-table">
        {rows}    
      </tbody>  
    </Table>
  );
}

function IdeaRow({ row, currentDate, calendarOptions, onApproved, setCalendar, setSchaduleDate }) {
  return (
    <tr>
      <UncontrolledTooltip target={`item_${row.id}`}>{row.description}</UncontrolledTooltip>
      <td id={`item_${row.id}`}>{row.title}</td>
      <td>
        <ScheduleDate currentDate={currentDate} onChange={date => setSchaduleDate(date, row.id)} />
      </td>

      {calendarOptions && calendarOptions.length > 1 && (
        <td>
          <CalendarSelect options={calendarOptions} onChange={e => setCalendar(e.value, row.id)} />
        </td>
      )}

      <td>
        <ApprovedButton onClick={e => onApproved(e, row.id)}></ApprovedButton>
      </td>
    </tr>
  );
}

function ApprovedButton({ onClick, children }) {
  return (
    <ButtonIcon icon="plus" onClick={onClick}>
      {children}
    </ButtonIcon>
  );
}

function CalendarSelect({ options, onChange }) {
  return <Select defaultValue={options[0]} options={options} onChange={onChange} />;
}

function ScheduleDate({ currentDate, onChange }) {
  const [startDate, setStartDate] = React.useState(currentDate);
  const changDate = date => {
    setStartDate(date);
    onChange(date);
  };
  return <DatePicker id="schadule_date" selected={startDate} onChange={changDate} dateFormat="yyyy-MM-dd" />;
}

function addDays(date, days) {
  date.setDate(date.getDate() + days);
  return date;
}