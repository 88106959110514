import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

export default function WaitingModal({ isOpen, inProgress, message, size, title, children, redirectUrl = undefined }) {
  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div>{message}</div>
        {children}
      </ModalBody>
      <ModalFooter>
        {inProgress && (
          <Button disabled>
            <Spinner size="sm" />
            <span>Processing...</span>
          </Button>
        )}
        {!inProgress && (
          <Link className="btn btn-falcon-primary" to={redirectUrl}>
            {' '}
            Go to your plan
          </Link>
        )}
      </ModalFooter>
    </Modal>
  );
}
