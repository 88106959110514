import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import { getCapacityReportsCsv } from 'api/capacity-reports';
import { formatDate } from 'api/utils';

export default function DownloadCapacityReportsCSVButton({ calendarApi }) {
  const handleClick = async () => {
    const start = calendarApi.view.currentStart;
    const end = calendarApi.view.currentEnd;
    const filename = `capacity_report_${formatDate(start, 'yyyyMMdd')}-${formatDate(end, 'yyyyMMdd')}.csv`;
    const [result, error] = await getCapacityReportsCsv(formatDate(start), formatDate(end));
    if (error) {
      toast.error(error);
      return;
    }
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <>
      <UncontrolledTooltip placement="bottom" target="download">
        download CSV
      </UncontrolledTooltip>
      <Button
        id="download"
        size="sm"
        color="falcon-primary"
        className="mr-2"
        aria-label="download csv"
        onClick={handleClick}
      >
        <FontAwesomeIcon icon="arrow-down" />
      </Button>
    </>
  );
}
