import React from 'react';
import { Modal, Form, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { toast } from 'react-toastify';

import ButtonIcon from 'falcon/components/ButtonIcon';

import { postChatGPTPrompImport } from 'api/importers';
import { WaitingModal } from 'atoms/form';
import { StepBegin, StepConsumer, StepBusiness, StepFinal, NavButton } from './Wizard';
import IdeasTable from './IdeasTable';

const businessFunctionOptionsData = [
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Human Resources', label: 'Human Resources' },
  { value: 'Information Technology', label: 'Information Technology' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Operations', label: 'Operations' },
  { value: 'Research and Development', label: 'Research and Development' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Sales', label: 'Sales' }
];

const plansOptionsData = [{ value: 'longForm', label: 'Long form' }, { value: 'shortForm', label: 'Short from' }];
const customerTypesOptionsData = [{ value: 'Consumer', label: 'Consumer' }, { value: 'Business', label: 'Business' }];

export default function AICreatePlanModal({ open, toggle, setLoading }) {
  const [formData, setFormData] = React.useState({});
  const [inProgress, setInProgress] = React.useState(false);
  // needs seprated useState variable for select so user can add or remove option from element
  const [functionsOption, setFunctionsOption] = React.useState();
  const [plansValue, setPlansValue] = React.useState(plansOptionsData[0]);
  const [customerTypeValue, setCustomerTypeValue] = React.useState(customerTypesOptionsData[0]);
  const [waitingFormParams, setWaitingFormParams] = React.useState({ isOpen: false, inProgress: false, message: '' });
  const [currentStep, setCurrentStep] = React.useState(1);
  const [ideas, setIdeas] = React.useState();

  const handleChanged = event => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const checkRequiredValue = (data, ...args) => {
    for (const item of args) {
      if (!data[item]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async event => {
    event.preventDefault();

    // Merge Selected value with formData
    formData['functions'] = functionsOption;
    formData['plans'] = plansValue;
    formData['target_customers'] = customerTypeValue;

    let isValidate = true;
    if (formData['target_customers'].value === 'Consumer') {
      isValidate &= checkRequiredValue(formData, 'ideal_customers');
    }
    if (formData['target_customers'].value === 'Business') {
      isValidate &= checkRequiredValue(formData, 'business_target_customers', 'business_industries');
    }

    if (!isValidate) {
      toast.error('There are some errors on the page.');
      return;
    }

    if (formData) {
      setInProgress(true);
      setLoading(inProgress);

      setWaitingFormParams({
        isOpen: true,
        inProgress: true,
        message: 'Burgundy 💡 is working on this ...'
      });
      const [response] = await postChatGPTPrompImport(formData);

      setWaitingFormParams({
        isOpen: !response.data['errors'],
        inProgress: false,
        message: `Your plan is created. Click here to see it ${response.data['calendarName'] &&
          response.data['calendarName']}`
      });
      setInProgress(false);
      setLoading(inProgress);

      if (response.data['errors']) {
        toast.error(response.data['errors']);
        return;
      } else {
        setIdeas(response.data['ideas']);
      }
    }
  };

  const moveForward = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };
  const moveBackward = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  let buttonContents = undefined;
  switch (currentStep) {
    case 1:
      buttonContents = <NavButton name="Next" type="button" icon="arrow-right" onClickHandle={moveForward} />;
      break;
    case 2:
      buttonContents = (
        <>
          <NavButton name="previous" type="button" icon="arrow-left" onClickHandle={moveBackward} />
          <NavButton name="Next" type="button" icon="arrow-right" onClickHandle={moveForward} />
        </>
      );
      break;
    case 3:
      buttonContents = (
        <>
          <NavButton name="previous" type="button" icon="arrow-left" onClickHandle={moveBackward} />
          <ButtonIcon color="falcon-primary" type="submit" icon="check" size="sm" className="m-2">
            Create Plan
          </ButtonIcon>
        </>
      );
      break;
    default:
      buttonContents = <NavButton name="Next" type="button" icon="arrow-right" onClickHandle={moveForward} />;
      break;
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      modalClassName="theme-modal"
      contentClassName="border"
      backdrop={!inProgress}
      keyboard={!inProgress}
    >
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          Draft a Plan ...
        </ModalHeader>
        <ModalBody>
          {currentStep === 1 && (
            <StepBegin
              customerTypeValue={customerTypeValue}
              customerTypOptions={customerTypesOptionsData}
              customerTypesChanged={setCustomerTypeValue}
            />
          )}
          {currentStep === 2 && customerTypeValue['value'] === 'Business' && (
            <StepBusiness
              wizardData={formData}
              businessFunctionValue={functionsOption}
              businessFunctionOptions={businessFunctionOptionsData}
              businessFunctionChanged={setFunctionsOption}
              handleChanged={handleChanged}
            />
          )}
          {currentStep === 2 && customerTypeValue['value'] === 'Consumer' && (
            <StepConsumer wizardData={formData} handleChanged={handleChanged} />
          )}
          {currentStep === 3 && (
            <StepFinal planOptionValue={plansValue} planOptions={plansOptionsData} planHandlChanded={setPlansValue} />
          )}

          <WaitingModal
            toggle={toggle}
            isOpen={waitingFormParams['isOpen']}
            inProgress={waitingFormParams['inProgress']}
            title={!ideas ? 'Please Wait' : 'Ideas Created in this Plan'}
            message={!ideas && waitingFormParams['message']}
            redirectUrl={'/calendars'}
            size={ideas ? 'xl' : ''}
          >
            {ideas && <IdeasTable ideas={ideas} plans={formData['plans']} setInProgress={setInProgress} />}
          </WaitingModal>
        </ModalBody>
        <ModalFooter>{buttonContents}</ModalFooter>
      </Form>
    </Modal>
  );
}
