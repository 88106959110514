import React from 'react';

import { StyledTooltip } from 'atoms/misc';

export default function CalendarEventTooltip({ event, project, calendarData, ...tooltipProps }) {
  return (
    <StyledTooltip {...tooltipProps}>
      {project.name && (
        <>
          <b>{project.name}</b>
          <br />
        </>
      )}
      {project.content && (
        <>
          <br />
          {project.content}
        </>
      )}

      {/* <b>{calendarData?.metric_label}:</b> {event.extendedProps.calendar_metric_value} ({calendarData.name}) */}
      {project.address && (
        <>
          <br />
          <b>Address:</b> {project.address}
        </>
      )}
      {project.external_id && (
        <>
          <br />
          <b>External Id:</b> ({project.external_id})
        </>
      )}
      {project.author && (
        <>
          <br />
          <b>Customer Name:</b> {project.author}
        </>
      )}
      {event.extendedProps.note && (
        <>
          <br />
          <b>Note:</b>{' '}
          {event.extendedProps.note.split('\n').map((paragraph, i) => (
            <React.Fragment key={i}>
              <br />
              {paragraph}
            </React.Fragment>
          ))}
        </>
      )}
      {event.extendedProps.tags && event.extendedProps.tags.length > 0 && (
        <>
          <br />
          <b>Tags:</b> {event.extendedProps.tags.map(tag => tag.label).join(', ')}
        </>
      )}
    </StyledTooltip>
  );
}
