import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

import GroupedInput from './GroupedInput';
import EditFieldAddon from './EditFieldAddon';

export default function EditableFieldWithPatchRequest({
  initialValue,
  groupedInputProps = {},
  patchRequest,
  fieldName,
  onSuccess,
  label,
  groupedInputClassName = 'text-left',
  onChange = (e, setNewValue) => {
    setNewValue(e.target.value);
  },
  onStartEditing = setNewValue => {
    setNewValue(cur => cur);
  },
  parseUserInput = val => val,
  readOnlyFormatter = val => val,
  // (inputRef) => {inputRef?.current?.setSelectionRange(3)}
  beforeFocus = () => {}
}) {
  const [newValue, setNewValue] = useState(initialValue);
  const [savedValue, setSavedValue] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);

  React.useEffect(() => {
    setSavedValue(initialValue);
    setNewValue(initialValue);
  }, [initialValue]);

  async function handleSave() {
    const parsedUserInput = parseUserInput(newValue);
    if (parsedUserInput === savedValue) {
      setEditing(false);
      setNewValue(parsedUserInput);
      return;
    }
    setLoading(true);
    const [res, err] = await patchRequest({
      [fieldName]: parsedUserInput
    });
    setLoading(false);
    if (err) {
      toast.error(JSON.stringify(err));
    } else {
      setSavedValue(parsedUserInput);
      setNewValue(parsedUserInput);
      onSuccess(res.data);
      setEditing(false);
    }
  }

  // this can't go in an event handler (ie, when edit button is clicked)
  // because the field will be disabled until next re-render.
  React.useEffect(() => {
    if (editing && inputRef.current && typeof inputRef.current.focus === 'function') {
      beforeFocus(inputRef);
      inputRef.current.focus();
    }
  }, [editing, inputRef, beforeFocus]);

  return (
    <GroupedInput
      ref={inputRef}
      name={fieldName}
      value={editing ? newValue : readOnlyFormatter(savedValue)}
      disabled={!editing}
      label={label}
      //TODO: formatters
      onChange={e => {
        onChange(e, setNewValue);
      }}
      className={groupedInputClassName}
      {...groupedInputProps}
    >
      <EditFieldAddon
        loading={loading}
        editing={editing}
        setEditing={setEditing}
        onStartEditing={() => {
          onStartEditing(setNewValue);
        }}
        onSave={handleSave}
      />
    </GroupedInput>
  );
}
