import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';

export default function StepBusiness({
  wizardData,
  businessFunctionValue,
  businessFunctionOptions,
  businessFunctionChanged,
  handleChanged
}) {
  return (
    <FormGroup>
      <legend>Tell us about your customers</legend>
      <Label for="business_industries">What industry or industries are your customers in? (Required)</Label>
      <Input
        id="business_industries"
        placeholder="e.g. consumer goods, manufacturing, health case, ..."
        value={wizardData['business_industries'] || ''}
        onChange={handleChanged}
        invalid={!wizardData['business_industries']}
      />

      <br />
      <Label for="business_functions">What business function is your solution for? (Optional)</Label>
      <Select
        id="business_functions"
        value={businessFunctionValue}
        options={businessFunctionOptions}
        onChange={businessFunctionChanged}
        isSearchable={false}
        isClearable={false}
      />

      <Label for="business_geography">What geography is your solution intended for? (Optional)</Label>
      <Input id="business_geography" value={wizardData['business_geography'] || ''} onChange={handleChanged} />

      <Label for="business_target_customers">Can you tell us a bit more about your target customers? (Required)</Label>
      <Input
        id="business_target_customers"
        type="textarea"
        rows={3}
        value={wizardData['business_target_customers'] || ''}
        onChange={handleChanged}
        placeholder="e.g. midsized manufactures which find it difficult to reconcile operational data from different systems."
        invalid={!wizardData['business_target_customers']}
      />

      <Label for="business_example_customers">Example customers: (optional)</Label>
      <Input
        id="business_example_customers"
        value={wizardData['business_example_customers'] || ''}
        onChange={handleChanged}
        placeholder="e.g. Walmart, KFC, Apple"
      />

      <Label for="business_example_competitors">Who are your direct competitors? (optional)</Label>
      <Input
        id="business_example_competitors"
        value={wizardData['business_example_competitors'] || ''}
        onChange={handleChanged}
        placeholder="e.g. Walmart, KFC, Apple"
      />

      <br />
      <legend>Tell us about your solution</legend>
      <Label for="business_solution">Briefly, what does your solution do? (Required; 400 characters max)</Label>
      <Input
        id="business_solution"
        type="textarea"
        rows={3}
        value={wizardData['business_solution'] || ''}
        onChange={handleChanged}
        placeholder="e.g. WidgetX helps retailers reach consumers through all their social media properties"
        maxLength={400}
      />

      <Label for="business_solution_details">Describe in details, what your solution does? (Optional)</Label>
      <Input
        id="business_solution_details"
        value={wizardData['business_solution_details'] || ''}
        type="textarea"
        rows={3}
        onChange={handleChanged}
      />
    </FormGroup>
  );
}
