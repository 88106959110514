import React from 'react';
import { Spinner, Card, CardBody } from 'reactstrap';
import FalconCardHeader from 'falcon/components/FalconCardHeader';

import AICreatePlanButton from './AICreatePlanButton';
import { UpdateCalendarFromDocButton } from './BulkUploadProjectsButton';
import postImageCalendarImport from '../../../api/importers/postImageImporter';

export default function AdminSection() {
  const [chatgptLoading, setChatGPTLoading] = React.useState(false);
  const [results, setResults] = React.useState({ response: null, error: null });
  const [loading, setLoading] = React.useState(false);

  const [imageUploadResult, setImageUploadResult] = React.useState(null);

  async function handleImageUpload(e) {
    e.preventDefault();
    setLoading(true);
    setImageUploadResult(null);
    const [response, error] = await postImageCalendarImport('hello', 1);
    setLoading(false);
    setImageUploadResult(error || response);
  }

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="Intelligent Copilot" />
        <CardBody className="d-flex">
          <div className="w-50">
            <p>Take advantage of our automated, intelligent planning features.</p>
            <h4>Create Content Plan</h4>
            <p>Base on your needs, our AI will create a new content plan for you.</p>
            <AICreatePlanButton setLoading={setChatGPTLoading} />
          </div>
          <div className="w-50">{chatgptLoading && <Spinner />}</div>
        </CardBody>

        <CardBody className="d-flex">
          <div className="w-50">
            <h5>Image Upload</h5>
            <p>Click the button below to see the Calendar Upload wizard.</p>
            <UpdateCalendarFromDocButton setResults={setResults} setLoading={setLoading} />
          </div>
        </CardBody>
      </Card>
    </>
  );
}
