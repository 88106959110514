import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form } from 'reactstrap';
import { toast } from 'react-toastify';

import { CreateProjectFormFields } from 'atoms/projects';
import { createProject } from 'api/projects';
import { useToastError } from 'utils';
import { useAuthContext } from 'context/auth';
import ButtonIcon from 'falcon/components/ButtonIcon';

const projectFormInitialState = () => ({
  name: '',
  external_id: '',
  address: '',
  author: '',
  customer_email: '',
  customer_phone: ''
});

export default function CreateProjectModal({
  open,
  toggle,
  // onSuccess(createProjectResponse, createOrderResponse)
  onSuccess = () => {}
}) {
  const setError = useToastError();
  const [newProjectFormState, setNewProjectFormState] = React.useState(projectFormInitialState());

  function reset() {
    setNewProjectFormState(projectFormInitialState());
  }

  const { authState } = useAuthContext();

  async function handleSubmit(e) {
    e.preventDefault();
    // Create a project and an order and set orderIds.
    const [createProjectResponse, createProjectError] = await createProject(newProjectFormState);
    if (createProjectError) {
      setError(createProjectError);
      return;
    }
    onSuccess(createProjectResponse);
    toast.success(`${authState.projectLabels.singularTitle} created.`);
    toggle();
    reset();
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          Create New {authState.projectLabels.singularTitle}
        </ModalHeader>
        <ModalBody>
          <CreateProjectFormFields state={newProjectFormState} setState={setNewProjectFormState} validate />
        </ModalBody>
        <ModalFooter>
          <ButtonIcon icon="check" color="falcon-primary" type="submit" className="px-4">
            Save
          </ButtonIcon>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
