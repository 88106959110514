import React, { useState } from 'react';
import ButtonIcon from 'falcon/components/ButtonIcon';

import AICreatePlanModal from './AICreatePlanModal';

export default function AICreatePlanButton({ setLoading }) {
  const [modalState, setModalState] = useState({ open: false });
  const toggleModal = () => setModalState(cur => ({ ...cur, open: !cur.open }));

  return (
    <>
      <ButtonIcon
        icon="sync-alt"
        transform="shrink-3 down-2"
        color="falcon-default"
        size="sm"
        className="m-2"
        onClick={toggleModal}
      >
        Create Plan
      </ButtonIcon>
      {modalState.open && <AICreatePlanModal toggle={toggleModal} open={modalState.open} setLoading={setLoading} />}
    </>
  );
}
