import { useCalendarsListContext } from 'context/calendars';
import { useAuthContext } from 'context/auth';

/** Only one level of children is allowed. */
export default function useRoutes() {
  const { calendars } = useCalendarsListContext();
  const { authState } = useAuthContext();

  const calendarsRoute = {
    id: 'calendars-route',
    initialIsOpen: true,
    icon: 'calendar-alt',
    name: 'Calendars',
    children: calendars.map(({ id, name, calendar_event_box_color }) => ({
      id,
      name,
      stackedIcon: true,
      iconColor: calendar_event_box_color,
      to: `/calendars/${id}`,
      icon: 'calendar-alt'
    }))
  };

  const projectTimelineRoute = {
    id: 'project-timeline-route',
    to: '/project-timeline',
    icon: 'list',
    name: 'Overview'
  };

  const projectsListRoute = {
    id: 'projects-list-route',
    exact: true,
    to: '/projects',
    icon: 'list',
    name: `All ${authState.user ? authState.projectLabels.pluralTitle : 'Projects'}`
  };

  const result = [calendarsRoute, projectTimelineRoute, projectsListRoute];

  const adminRoute = {
    id: 'admin-route',
    initialIsOpen: false,
    icon: 'sliders-h',
    name: 'Settings',
    children: [
      {
        id: 'admin-data-sync',
        name: 'Burgundy 💡',
        to: '/admin/data-sync',
        icon: 'sync'
      },
      {
        id: 'admin-users',
        name: 'Users',
        to: '/admin/users',
        icon: 'user'
      },
      {
        id: 'admin-company',
        name: 'Team Profile',
        to: '/admin/company',
        icon: 'cog'
      },
      {
        id: 'admin-calendars',
        name: 'Calendars',
        to: '/admin/calendars',
        icon: 'calendar-alt'
      }
    ]
  };

  if (authState.user?.isAdmin) {
    result.push(adminRoute);
  }

  return result;
}
