import { patchCalendarItem } from 'api/calendar-items';

export default async function moveEvent(e, setCalendarItems, setError, transformCalendarItem) {
  const [res, err] = await patchCalendarItem(e.event.id, {
    start: e.event.start,
    end: e.event.end
  });
  if (err) {
    setError(err);
    e.revert();
    return;
  }
  // filter the old element from calendar items and add the new one in.
  setCalendarItems(cur => [...cur.filter(el => el.id !== Number(e.event.id)), transformCalendarItem(res.data)]);
}
