import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Card, CardBody, CardText } from 'reactstrap';
import FalconCardHeader from 'falcon/components/FalconCardHeader';

import { getProjectDetails, patchProject } from 'api/projects';
import { useToastError } from 'utils';
import { EditableFieldWithPatchRequest } from 'atoms/form';
import { useAuthContext } from 'context/auth';
import { DeleteProjectButton, GenerateForProjectButton, ProjectActivityLog } from 'atoms/projects';
import { PermissionRequired } from 'atoms/users';
import { Tooltip } from 'reactstrap';

import ExtraFieldsList from './ExtraFieldsList';

const FAILED_UPDATE_ERROR_MESSAGE = 'Failed to update project';

export default function ProjectDetailsPage() {
  const setError = useToastError();
  const { id } = useParams();
  const [project, setProject] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0); // State to trigger refresh
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();

  React.useEffect(() => {
    async function getProject() {
      const [res, err] = await getProjectDetails(id);
      if (err) {
        setError(`Failed to get ${singularTitle}.`);
        setProject();
        return;
      }
      setProject(res.data);
    }
    getProject();
  }, [id, setError, singularTitle, refreshKey]); // Add refreshKey to dependencies

  const patchProjectRequest = async body => await patchProject(id, body);

  const history = useHistory();

  if (!project) return <></>;

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title={`${singularTitle} Details`}>
          <PermissionRequired permission="delete_project">
            <GenerateForProjectButton
              icon="arrow-right"
              size="sm"
              className="m-2 btn-falcon-primary"
              id={id}
              onSuccess={() => setRefreshKey(prev => prev + 1)} // Update refreshKey on success
            />
            <DeleteProjectButton
              icon="trash-alt"
              size="sm"
              className="m-2 btn-falcon-danger"
              id={id}
              onSuccess={() => {
                history.push('/');
              }}
            />
          </PermissionRequired>
        </FalconCardHeader>
        <CardBody>
          <EditableFieldWithPatchRequest
            initialValue={project.name}
            label={`${singularTitle} Title`}
            patchRequest={patchProjectRequest}
            fieldName="name"
            errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
            onSuccess={setProject}
          />
          <EditableFieldWithPatchRequest
            initialValue={project.address || ''}
            label={`${singularTitle} Link`}
            patchRequest={patchProjectRequest}
            fieldName="address"
            errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
            onSuccess={setProject}
          />
          <EditableFieldWithPatchRequest
            initialValue={project.author || ''}
            label="Author"
            patchRequest={patchProjectRequest}
            fieldName="Author"
            errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
            onSuccess={setProject}
          />
          <EditableFieldWithPatchRequest
            initialValue={project.image_url || ''}
            label="Image URL"
            patchRequest={patchProjectRequest}
            fieldName="image_url"
            errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
            onSuccess={setProject}
          />
          {false && project.image_url && (
            <>
              <span id="image-preview-tooltip" role="img" aria-label="Preview Image">
                🔍 Preview Image
              </span>
              <Tooltip placement="right" isOpen={tooltipOpen} target="image-preview-tooltip" toggle={toggleTooltip}>
                <img src={project.image_url} alt="preview" style={{ width: '600px' }} />
              </Tooltip>
            </>
          )}
          <CardText>
            <EditableFieldWithPatchRequest
              initialValue={project.content || ''}
              label="Content"
              patchRequest={patchProjectRequest}
              fieldName="content"
              errorMessage={FAILED_UPDATE_ERROR_MESSAGE}
              onSuccess={setProject}
              groupedInputProps={{
                type: 'textarea',
                rows: 2
              }}
            />
          </CardText>
          {project.extra_fields_json && <ExtraFieldsList project={project} key={refreshKey} />}
        </CardBody>
      </Card>
      {
        <PermissionRequired permission="view_projectactivitylog">
          <ProjectActivityLog projectId={id} />
        </PermissionRequired>
      }
    </>
  );
}
