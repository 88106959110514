import React from 'react';

import { TagBadge } from 'atoms/calendars';

const CalendarEventBox = ({ project, id, titleLinksToProject, calendarItem }) => {
  return (
    <div className="text-wrap cursor-pointer" id={id}>
      <div className="d-flex">
        {calendarItem?.tags.map(tag => (
          <React.Fragment key={tag.id}>
            <TagBadge color={tag.color} />
          </React.Fragment>
        ))}
      </div>
      <strong>
        {titleLinksToProject ? (
          <a href={`/projects/${project.id}`}>
            {project.name}
          </a>
        ) : (
          <>
            {project.name}
          </>
        )}
      </strong>
    </div>
  );
};

export default CalendarEventBox;
