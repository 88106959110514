import { apiHandlerWithError } from 'api/apiHandler';
import { addQueryParams } from 'api';

async function getConsumerPersonaMiniHelper(input_text) {
  return await apiHandlerWithError(
    addQueryParams('minihelper', { helper: 'consumer_persona', input_text: input_text })
  );
}

async function getGeographyMiniHelper(input_text) {
  return await apiHandlerWithError(addQueryParams('minihelper', { helper: 'geography', input_text: input_text }));
}

export { getConsumerPersonaMiniHelper, getGeographyMiniHelper };
