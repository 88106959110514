import React from 'react';
import { Modal, ModalHeader, ModalBody, Form, ModalFooter } from 'reactstrap';
import ButtonIcon from 'falcon/components/ButtonIcon';
import { addDays } from 'date-fns';

import { CreateProjectFormFields, SelectProjectDropdown } from 'atoms/projects';
import { CalendarItemFormFields } from 'atoms/calendars';
import { createProject } from 'api/projects';
import { createCalendarItem } from 'api/calendar-items';
import { CheckboxWithLabel } from 'atoms/form';
import { useToastError } from 'utils';
import { useAuthContext } from 'context/auth';

const projectFormInitialState = () => ({
  name: '',
  external_id: '',
  address: '',
  author: '',
  content: '',
  image_url: ''
});

const calendarItemFormInitialState = ({ initialStartDate, initialEndDate }) => {
  return {
    start: initialStartDate || '',
    // Handles the exclusive storage but inclusive display of end dates.
    end: initialEndDate && initialEndDate > addDays(initialStartDate, 1) ? initialEndDate : null,
    calendar_metric_value: 0,
    note: ''
  };
};

export default function CreateCalendarItemModal({
  calendarData,
  open,
  toggle,
  setCalendarItems,
  initialStartDate,
  initialEndDate,
  backlogProjects,
  refreshBacklog
}) {
  const {
    authState: {
      projectLabels: { singularTitle }
    }
  } = useAuthContext();
  const setError = useToastError();
  const [existingProject, setExistingProject] = React.useState(null);
  const [creatingNewProject, setCreatingNewProject] = React.useState(false);
  const [newProjectFormState, setNewProjectFormState] = React.useState(projectFormInitialState());
  const [calendarItemFormState, setCalendarItemFormState] = React.useState(
    calendarItemFormInitialState({ initialStartDate, initialEndDate })
  );

  function reset() {
    setExistingProject(null);
    setCreatingNewProject(false);
    setNewProjectFormState(projectFormInitialState());
    setCalendarItemFormState(calendarItemFormInitialState({ initialStartDate, initialEndDate }));
  }

  React.useEffect(() => {
    const { start, end } = calendarItemFormInitialState({ initialStartDate, initialEndDate });
    setCalendarItemFormState(cur => ({
      ...cur,
      start,
      end
    }));
  }, [initialStartDate, initialEndDate]);

  async function handleSubmit(e) {
    e.preventDefault();
    let projectId;
    if (!creatingNewProject) {
      if (!existingProject) {
        setError(`Must select existing ${singularTitle} or create new ${singularTitle}.`);
        return;
      }
      projectId = existingProject.id;
    } else {
      // Create a project and set projectId.
      const [createProjectResponse, createProjectError] = await createProject(newProjectFormState);
      if (createProjectError) {
        setError(createProjectError);
        return;
      }
      projectId = createProjectResponse.data.id;
    }
    const [createCalendarItemResponse, createCalendarItemError] = await createCalendarItem(calendarData.id, {
      ...calendarItemFormState,
      project: projectId
    });
    if (createCalendarItemError) {
      setError(createCalendarItemError);
      return;
    }
    setCalendarItems(cur => [...cur, createCalendarItemResponse.data]);
    toggle();
    reset();
    refreshBacklog();
  }

  return (
    <Modal isOpen={open} toggle={toggle} modalClassName="theme-modal" contentClassName="border">
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle} className="bg-light d-flex flex-between-center border-bottom-0">
          Schedule New Item on "{calendarData?.name}" Calendar
        </ModalHeader>
        <ModalBody>
          <h4 className="h5">{singularTitle}</h4>
          <CheckboxWithLabel
            className="my-2"
            label={`Create New ${singularTitle}`}
            id="creating-new-project-checkbox"
            checked={creatingNewProject}
            onChange={e => {
              setCreatingNewProject(e.target.checked);
            }}
          />
          {creatingNewProject ? (
            <CreateProjectFormFields
              state={newProjectFormState}
              setState={setNewProjectFormState}
              validate={creatingNewProject}
            />
          ) : (
            <SelectProjectDropdown
              options={backlogProjects}
              label={`Select Existing ${singularTitle} (Required)`}
              state={existingProject}
              setState={setExistingProject}
              isDisabled={creatingNewProject}
            />
          )}
          <h5 className="mt-3">Scheduling Info</h5>
          <CalendarItemFormFields
            calendarData={calendarData}
            state={calendarItemFormState}
            setState={setCalendarItemFormState}
          />
        </ModalBody>
        <ModalFooter className="bg-light px-card border-top-0">
          <ButtonIcon color="falcon-primary" type="submit" icon="check" size="sm" className="m-2">
            Save
          </ButtonIcon>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
