import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardHeader, Spinner } from 'reactstrap';
import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

import { getProjects } from 'api/projects';
import { useAuthContext } from 'context/auth';
import Table from 'atoms/misc/Table';
import { useProjectFields, CreateProjectButton } from 'atoms/projects';

import {projectName} from 'config';

const sort = {
  sortCaret: function SortCaret(order) {
    return <FontAwesomeIcon className="ml-3" icon={!order ? 'sort' : order === 'asc' ? 'caret-up' : 'caret-down'} />;
  }
};

export default function ProjectsList() {
  const [projects, setProjects] = React.useState([]);
  const [error, setError] = React.useState(null);
  const {
    authState: { projectLabels }
  } = useAuthContext();
  const projectFields = useProjectFields();

  const noProjectsErrorText = `There are currently no ${projectLabels.plural} to show here. You can add ${
    projectLabels.plural
  } individually with the Add ${projectLabels.singularTitle} button above, or bulk upload ${
    projectLabels.plural
  } via the Admin page. Alternatively, you can speak with PlanBoard about a custom automated integration with you existing ERP, CRM, etc.`;

  React.useEffect(() => {
    setError(null);
    async function callApi() {
      const [res, err] = await getProjects();
      if (err) {
        setError(err);
        setProjects([]);
        return;
      }
      if (res.data.length === 0) {
        setError(noProjectsErrorText);
      }
      setProjects(res.data);
    }
    callApi();
  }, [noProjectsErrorText]);

  return (
    <Card>
      <CardHeader className="bg-light d-flex align-items-center justify-content-between">
        <h4 className="mb-0">{projectLabels.pluralTitle}</h4>
        <div className="d-flex align-items-center">
          <CSVLink
            data={projects}
            headers={projectFields}
            filename={`PlanBoard-${projectLabels.pluralTitle}-${format(new Date(), 'yyyy-MM-dd-HH_mm_ss')}.csv`}
          >
            Download as CSV
          </CSVLink>
          <CreateProjectButton
            onSuccess={res => {
              setProjects(cur => [...cur, res.data]);
            }}
            buttonIconProps={{
              color: 'falcon-default',
              size: 'sm',
              className: 'ml-3'
            }}
          />
        </div>
      </CardHeader>
      <CardBody>
        {projects && projects.length > 0 ? (
          <>
            <Table
              sort={sort}
              columns={[
                {
                  dataField: 'id',
                  text: `${projectName} ID`,
                  sort: true,
                  align: 'right',
                  formatter: function idColumnFormatter(id) {
                    return <Link to={`/projects/${id}`}>{id}</Link>;
                  }
                },
                ...projectFields.map(({ key, label }) => ({
                  dataField: key,
                  text: label,
                  sort: true,
                  align: 'right'
                }))
              ]}
              defaultSorted={[
                {
                  dataField: 'id',
                  order: 'desc'
                }
              ]}
              data={projects}
              keyField="id"
              classes="table-dashboard table-striped fs--1 border-bottom mb-0 table-dashboard-th-nowrap"
              rowClasses="btn-reveal-trigger"
              headerClasses="bg-400 text-900"
            />
          </>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <Spinner />
        )}
      </CardBody>
    </Card>
  );
}
